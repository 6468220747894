<template>
	<div class="section">
		<div class="container">

			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Переводы</div>

						<div class="section__options">
							<div class="section__option">
								<button class="btn btn--sm" @click="onSave()">Создать</button>
							</div>
						</div>
					</div>

					<div class="section__sub">
						<div class="section__infoWrap">
							<div class="filter">
								<div class="filter__wrap">
									<div class="filter__item">
										<form-input v-model="search"
												name="search"
												label="Key"
												:filterType="true"></form-input>
									</div>

									<!--<div class="filter__item">-->
										<!--<form-input v-model="search"-->
												<!--name="search"-->
												<!--label="Текст"-->
												<!--:filterType="true"></form-input>-->
									<!--</div>-->

									<!--<div class="filter__item">-->
										<!--<form-select v-model="scopes"-->
												<!--:options="translateScopes"-->
												<!--label="Scopes"-->
												<!--option_label="description"-->
												<!--name="scopes"></form-select>-->
									<!--</div>-->

									<div class="_flex _justify-end" v-if="hasFilterRun">
										<button class="btn btn--mini" @click="resetFilter">Очистить</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="section__main">
						<v-table :headers="tabelHeaders"
								:items="list"
								:hasLoadList="loadingGetList"
								:pagination="listPagination"
								@changeLimit="onPaginationChangeLimit"
								@changePage="onPaginationChangePage">
							<tr v-for="(td, key) in list" :key="td.id">
								<td>{{td.key}}</td>
								<td>{{td.text[selectedLang]}}</td>
								<td>
									<span class="table__tab" v-for="scope in td.scopes">{{scope.description}}</span>
								</td>
								<td>
									<div class="table__options">
										<div class="table__option" @click="onSave(td)">
											<v-icon name="edit"></v-icon>
										</div>

										<div class="table__option">
											<alert-modal :class="{'has-disabled': loadingDeleteItem}" @success="onDelete(td.id)">
												<v-icon name="trash-alt"></v-icon>
											</alert-modal>
										</div>
									</div>
								</td>
							</tr>
						</v-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import VTable from '@components/VTable';
	import TranslatesSave from './TranslatesSave';

	export default {
		name: 'translates-index',
		components:{
			VTable,
		},
		data(){
			return {
				tabelHeaders: [
					{key:'key', text: 'Key',},
					{key:'text', text: 'Текст'},
					{key:'scope', text: 'Модуль'},
					{key:'nav', text: ''},
				]
			}
		},

		computed:{
			...mapState({
				list: state => state.translates.list,
				listPagination: state => state.translates.listPagination,
				listFilter: state => state.translates.listFilter,
				loadingGetList: state => state.translates.loadingGetList,
				loadingDeleteItem: state => state.translates.loadingDeleteItem,

				selectedLang: state => state.selectedLang,
				translateScopes: state => state.translateScopes.list
			}),

			...mapGetters({
				listActiveFilter: 'translates/listActiveFilter'
			}),

			// key:{
			// 	get(){
			// 		return this.listFilter.key
			// 	},
			// 	set(val){
			// 		this.SET_FILTER_LIST({key: 'key', val: val});
			// 		this.getList();
			// 	}
			// },

			search:{
				get(){
					return this.listFilter['search']
				},
				set(val){
					this.SET_FILTER_LIST({key: 'search', val: val});
					this.getList();
				}
			},

			// scopes:{
			// 	get(){
			// 		return
			// 		return $fn.find(this.translateScopes,['id', +this.listFilter.scopes])
			// 	},
			// 	set(val){
			// 		this.SET_FILTER_LIST({key: 'scopes', val: val ? map(val, el => {
			// 			return el.id
			// 			}) : null});
			// 		this.getList();
			// 	}
			// },

			hasFilterRun(){
				return this.search;
			}
		},

		created(){
			this.getFilterDataFromUrl();

			this.getList();
			this.initCommonData();

		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'translates/SET_FILTER_LIST'
			}),
			...mapActions({
				GET_LIST: 'translates/GET_LIST',
				DELETE_ITEM: 'translates/DELETE_ITEM',
				GET_TRANSLATE_SCOPES: 'translateScopes/GET_LIST'
			}),


			onSave(item){
				this.$modal.show(TranslatesSave,{
					item: item,
				}, $MODAL_OPTIONS.default);
			},

			onDelete(id){
				this.DELETE_ITEM(id);
			},

			getList: $fn.debounce(function () {
				this.GET_LIST();
				this.setFilterDataToUrl();
			}, 500),

			initCommonData(){
				this.GET_TRANSLATE_SCOPES();
			},

			getFilterDataFromUrl(){
				let query = this.$route.query;

				$fn.forIn(query,(val, key) => {
					this.SET_FILTER_LIST({key: key, val: val});
				})
			},

			setFilterDataToUrl(){
				this.$router.replace({ name: this.$route.name, query: this.listActiveFilter }).catch(() => {});
			},

			onPaginationChangeLimit(limit){
				this.SET_FILTER_LIST({key: 'limit', val: limit});
				this.getList();
			},

			onPaginationChangePage(page){
				this.SET_FILTER_LIST({key: 'page', val: page});
				this.getList();
			},

			resetFilter(){
				this.search = null;
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
