<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__title">{{hasEditType ? 'Edit' : 'Save'}} статический перевод</div>
					</div>

					<div class="lang">
						<div class="lang__tabs">
							<div class="lang__tab" v-for="lang in languages"
									:class="{'has-active': lang.code === selectedLang}"
									@click="selectedLang = lang.code">{{lang.code}}</div>
						</div>
						<div class="lang__cnt">
							<div class="lang__form" v-for="lang in languages" v-if="lang.code === selectedLang">
								<div class="form__item">
									<form-textarea v-model="formData.text[lang.code]"
											name="text"
											@enter="onSave"
											rules="required"
											label="Text"></form-textarea>
								</div>
							</div>
						</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--12">
							<div class="form__item">
								<form-input v-model="formData.key"
										name="key"
										@enter="onSave"
										rules="required"
										label="Key"></form-input>
							</div>
						</div>

						<div class="grid__coll grid__coll--12">
							<div class="form__item">
								<div class="form__item">
									<form-select-tags v-model="formData.scopes"
											rules="required"
											:options="translateScopes"
											label="Module"
											option_label="description"
											name="scope"></form-select-tags>
								</div>
							</div>
						</div>
					</div>

					<div class="form__footer">
						<button class="btn"
								:class="{'has-loading': loadingSaveItem}"
								@click="onSave"><span>{{hasEditType ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'translates-save',
		props: ['item'],
		data(){
			return{
				selectedLang : this.$store.state.selectedLang,
				formData:{
					key: null,
					scopes: null,
					text: {}
				}
			}
		},

		computed:{
			...mapState({
				loadingSaveItem: state => state.translates.loadingSaveItem,
				languages: state => state.languages.list,

				translateScopes: state => state.translateScopes.list
			}),

			hasEditType(){
				return !!this.item;
			},
		},

		created(){
			this.initCommonData();
			if(this.hasEditType) this.setFormData()
		},

		methods:{
			...mapActions({
				SAVE_ITEM: 'translates/SAVE_ITEM',
				GET_TRANSLATE_SCOPES: 'translateScopes/GET_LIST'
			}),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						let formData = $fn.cloneDeep(this.formData);

						this.SAVE_ITEM($fn.assign(formData,{
							id: this.hasEditType ? this.item.id : null,
							scope_ids: $fn.map(formData.scopes, el => {
								return el.id
							})
						})).then((resp) =>{
							this.$emit('close');

						}).catch(err => {
							$fn.setValidateErrors(err, this.errors);
						})
					}
				});
			},

			setFormData(){
				$fn.assign(this.formData, this.item);
			},

			initCommonData(){
				this.GET_TRANSLATE_SCOPES();
			},
		}
	};
</script>

<style scoped lang=scss>

</style>
